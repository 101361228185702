@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
	font-family: 'Noto Sans', sans-serif;
}

button {
	@apply max-sm:text-sm max-sm:px-7 px-4 py-4 md:py-[18px] md:px-[36px];
}
@layer components {
	.max-container {
		max-width: 1248px;
		margin: 0 auto;
	}
	.gradient-text {
		background: var(
			--gradient-gradient-primary,
			linear-gradient(270deg, #704998 0%, #66418b 45.1%, #3d286b 100%)
		);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.button-gradient {
		background: var(
			--gradient-gradient-primary,
			linear-gradient(270deg, #704998 0%, #66418b 45.1%, #3d286b 100%)
		);
	}
	.Link-hover {
		background: rgba(255, 255, 255, 0.12);
	}
	.hover-underline {
		display: inline;
		position: relative;
		color: #704998;
	}

	.hover-underline::after {
		content: '';
		position: absolute;
		width: 70%;
		transform: scaleX(0);
		height: 1.5px;
		bottom: 0;
		left: 30px;
		background-color: #704998;
		transform-origin: bottom right;
		transition: transform 0.4s ease-in-out;
	}

	.hover-underline:hover::after {
		transform: scaleX(1);
		transform-origin: bottom left;
	}
}

@layer utilities {
	.button-hover {
		@apply hover:bg-white-500 text-black;
	}
	.circle-icon {
		@apply flex justify-center items-center w-[24px] h-[24px] rounded-full bg-purple-600 text-purple-500;
	}
	.small-icon {
		@apply h-[32px] w-[32px];
	}
	.button-purple {
		@apply rounded-xl text-white font-bold;
	}
	.button-transparent {
		@apply rounded-xl border border-purple-500 text-purple-500 font-medium;
	}

	.padding-x {
		@apply max-lg:px-6 max-2xl:px-8;
	}
	/* .padding-y {
		@apply pt-[104px] pb-[90px];
	} */
	.padding-y {
		@apply pt-20 pb-28;
	}
	.padding-y-23 {
		@apply py-[92px];
	}
	.padding-y-30 {
		@apply md:pt-[121px] xl:pb-[200px];
	}
	.copyright-background {
		background: rgba(255, 255, 255, 0.12);
	}

	.top-title {
		@apply font-medium text-[14px] md:text-[20px] leading-[22px] uppercase text-purple-500 tracking-[2px];
	}
	.title {
		@apply text-[26px] leading-[36px] md:text-4xl font-semibold mt-3 mb-4;
	}
	.sub-title {
		@apply text-sm md:text-lg text-black-300;
	}
	.heading-text {
		@apply max-sm:text-2xl text-6xl font-semibold;
	}
	/* .text-desc {
		@apply text-sm md:text-lg;
	} */
	.portfolio-card {
		@apply py-11 px-10 xl:px-[78px] text-center;
	}
	.contact-card {
		@apply flex flex-col items-center bg-purple-500 rounded-xl text-center py-11 px-10 xl:px-28 text-white-400;
	}
	.footer-link {
		@apply flex items-center gap-2 text-lg mb-3 md:mb-5 cursor-pointer text-[#D9D9D9];
	}
	.footer-icon {
		@apply h-[12px] w-[12px];
	}
	.footer-icon-transition {
		@apply transition-colors duration-300 ease-in-out hover:bg-white-500 hover:text-purple-500;
	}
	.footer-bg {
		@apply flex items-center justify-center rounded-full bg-[#9A8FB2] text-[#FFFFFF] p-1;
	}
	.button-transition {
		@apply transition ease-in-out duration-500;
	}
	.button-purple-hover {
		@apply hover:bg-purple-300 hover:text-white;
	}
	.purple-icon-transition {
		@apply transition-colors duration-300 ease-in-out hover:text-white-400 hover:bg-purple-300;
	}
}

.testimonial-pagination {
	@apply flex gap-2 justify-center items-center;
}
/* .testimonial-bullet {
	@apply h-[14px] w-[14px] rounded-full bg-purple-100;
} */
.testimonial-bullet-active {
	@apply h-[14px] w-[14px] rounded-full bg-purple-500 !important;
}
.testimonial-title {
	@apply text-xs leading-[18px] md:text-2xl font-semibold pt-8 pb-2.5;
}
.testimonial-desc {
	@apply text-[9px] leading-[14px] md:text-lg text-black-300;
}
.slick-initialized .slick-slide {
	height: 156px;
	display: flex !important;
	align-items: center;
	justify-content: center;
}
.truncated-text {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.box-shadow {
	box-shadow: 0px 8px 12px 0px rgba(17, 17, 17, 0.04);
}

/* Add these styles to your CSS file */
::-webkit-scrollbar {
	width: 4px;
	border-radius: 4px; /* Adjust border radius as needed */
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 4px; /* Adjust border radius to match the track */
}

::-webkit-scrollbar-thumb:hover {
	background-color: #555;
}

.swiper {
	width: 100%;
	height: 100%;
}

/* index.css
@media (min-width: 412px) {
	.max-container {
	  padding-top: 64px;
	}
  } */
